<script>
import draggable from 'vuedraggable'
import render from '@/common/js/render'

const components = {
  itemBtns(h, element, index, parent) {
    const { copyItem, deleteItem } = this.$listeners
    return [
      <span class="drawing-item-copy" title="复制" onClick={event => {
        copyItem(element, parent); event.stopPropagation()
      }}>
        <i class="el-icon-copy-document" />
      </span>,
      <span class="drawing-item-delete" title="删除" onClick={event => {
        deleteItem(index, parent); event.stopPropagation()
      }}>
        <i class="el-icon-delete" />
      </span>
    ]
  }
}
const layouts = {
  colFormItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    return (
      <el-col span={element.span} class={className}
        nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
        <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
          label={element.label} required={element.required}>
          <render key={element.renderKey} conf={element} onInput={event => {
            this.$set(element, 'defaultValue', event)
          }} />
        </el-form-item>
        {components.itemBtns.apply(this, arguments)}
      </el-col>
    )
  },
  rowFormItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    const className = this.activeId === element.formId ? 'drawing-row-item active-from-item' : 'drawing-row-item'
    let child = renderChildren.apply(this, arguments)
    if (element.type === 'flex') {
      child = <el-row type={element.type} justify={element.justify} align={element.align}>
        {child}
      </el-row>
    }
    return (
      <el-col span={element.span}>
        <el-row gutter={element.gutter} class={className}
          nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
          <span class="component-name">{element.componentName}</span>
          <draggable list={element.children} animation={340} group="componentsGroup" class="drag-wrapper">
            {child}
          </draggable>
          {components.itemBtns.apply(this, arguments)}
        </el-row>
      </el-col>
    )
  },
  heheItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    let style = {
      width: element.width + '%',
      height: element.height + 'px',
      paddingLeft: element.paddingLeft + 'px',
      paddingRight: element.paddingRight + 'px',
      paddingTop: element.paddingTop + 'px',
      paddingBottom: element.paddingBottom + 'px',
      marginLeft: element.marginLeft + 'px',
      marginRight: element.marginRight + 'px',
      marginTop: element.marginTop + 'px',
      marginBottom: element.marginBottom + 'px',
      borderWidth: element.borderWidth + 'px',
      borderStyle: 'solid',
      borderColor: element.isBorder ? '#000' : "transparent",
      fontSize: element.fontSize + 'px',
      lineHeight: element.lineHeight + 'px',
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    let styleLabel = {
      width: element.labelWidth + 'px',
      textAlign: element.labelAlign,
      marginRight: element.labelRight + 'px',
    }
    let styleValue = {
      flex: 1,
      // maxWidth:`calc(100% - ${element.labelWidth}px - 5px)`
    }
    let styleBox = {
      display: 'flex',
      alignItems: element.alignItems,
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <p class="label" style={styleLabel}>{element.label}</p>
          <p class="value" id={element.bindValue} style={styleValue}>{element.default}</p>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  feItem(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    // if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    let oneItemWidth = (this.formConf.formList[this.formConf.index].width - 20) / this.formConf.formList[this.formConf.index].maxNum
    oneItemWidth = oneItemWidth.toFixed(3)
    let style = {
      width: element.width * oneItemWidth + 'px',
      height: element.fontSize + 10 + 'px',
      fontSize: element.fontSize + 'px',
      lineHeight: element.fontSize + 10 + 'px',
      display: 'inline-block',
      overflow: 'hidden',
    }
    let styleLabel = {
      width: element.labelWidth * oneItemWidth + 'px',
      textAlign: element.labelAlign,
      fontWeight: element.bold ? 'bold' : 'normal'
    }
    let styleValue = {
      flex: 1,
      textAlign: element.textItems,
      fontWeight: element.bold ? 'bold' : 'normal'
      // maxWidth:`calc(100% - ${element.labelWidth}px - 5px)`
    }
    let styleBox = {
      display: 'flex',
      // alignItems: element.alignItems,
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <div style={styleBox}>
          <p class="label" style={styleLabel}>{element.label}</p>
          <p class="value" id={element.bindValue} style={styleValue}>{element.default}</p>
        </div>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  heheCode(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    let style = {
      width: element.width + '%',
      paddingLeft: element.paddingLeft + 'px',
      paddingRight: element.paddingRight + 'px',
      paddingTop: element.paddingTop + 'px',
      paddingBottom: element.paddingBottom + 'px',
      marginLeft: element.marginLeft + 'px',
      marginRight: element.marginRight + 'px',
      marginTop: element.marginTop + 'px',
      marginBottom: element.marginBottom + 'px',
      borderWidth: element.borderWidth + 'px',
      borderStyle: 'solid',
      borderColor: element.isBorder ? '#000' : "transparent",
      display: 'inline-block',
      overflow: 'hidden',
      top: element.top + 'px',
      left: element.left + 'px',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    let styleBox = {
      display: 'block',
      width: '100%',
      height: "auto"
    }
    const src = require('@/assets/code.png')
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        <img style={styleBox} src={src} alt="" />
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
  heheLine(h, element, index, parent) {
    const { activeItem } = this.$listeners
    let className = this.activeId === element.formId ? 'drawing-item active-from-item' : 'drawing-item'
    if (this.formConf.unFocusedComponentBorder) className += ' unfocus-bordered'
    let style = {
      width: element.width + '%',
      height: element.height + 'px',
      marginLeft: element.marginLeft + 'px',
      marginRight: element.marginRight + 'px',
      marginTop: element.marginTop + 'px',
      marginBottom: element.marginBottom + 'px',
      display: 'inline-block',
      top: element.top + 'px',
      left: element.left + 'px',
      background: '#000',
      position: element.isFloat ? 'absolute' : 'relative'
    }
    return (
      // <el-col span={element.span} class={className}
      //   nativeOnClick={event => { activeItem(element); event.stopPropagation() }}>
      //   <el-form-item label-width={element.labelWidth ? `${element.labelWidth}px` : null}
      //     label={element.label} required={element.required}>
      //     <render key={element.renderKey} conf={element} onInput={ event => {
      //       this.$set(element, 'defaultValue', event)
      //     }} />
      //   </el-form-item>
      //   {components.itemBtns.apply(this, arguments)}
      // </el-col>
      <div class={className} style={style} onClick={event => { activeItem(element); event.stopPropagation() }}>
        {components.itemBtns.apply(this, arguments)}
      </div>
    )
  },
}

function renderChildren(h, element, index, parent) {
  if (!Array.isArray(element.children)) return null
  return element.children.map((el, i) => {
    const layout = layouts[el.layout]
    if (layout) {
      return layout.call(this, h, el, i, element.children)
    }
    return layoutIsNotFound()
  })
}

function layoutIsNotFound() {
  throw new Error(`没有与${this.element.layout}匹配的layout`)
}

export default {
  components: {
    render,
    draggable
  },
  props: [
    'element',
    'index',
    'drawingList',
    'activeId',
    'formConf'
  ],
  render(h) {
    const layout = layouts[this.element.layout]
    if (layout) {
      return layout.call(this, h, this.element, this.index, this.drawingList)
    }

    return layoutIsNotFound()
  }
}
</script>
